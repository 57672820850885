import { gql } from "graphql.macro";
import { useCallback } from "react";
import { ActionButton } from "../../../components/ActionButton";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Modal } from "../../../components/Modal";
import { useBackButton } from "../../../components/Overpage/BackButton";
import { useDeleteTalentMutation } from "../../../graphql";
import { TalentType } from "../../../types";

interface DeleteButtonProps {
  talent: TalentType;
}

export function DeleteButton({ talent }: DeleteButtonProps) {
  const [deleteTalent, { loading }] = useDeleteTalentMutation();
  const handleNavigateBack = useBackButton({
    defaultTo: `/talents`,
  });

  const handleDeleteClick = useCallback(() => {
    return deleteTalent({
      variables: {
        input: {
          id: talent.id,
          clientMutationId: null,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: talent.id,
          __typename: "Card",
        });
        if (talent.categories && talent.categories.length > 0) {
          for (let i = 0; i < talent.categories?.length; i++) {
            cache.updateFragment(
              {
                id: cache.identify({
                  id: talent.categories[i].id,
                  __typename: "Category",
                }),
                fragment: gql`
                  fragment MyCategory on Category {
                    id
                    cardsCount
                  }
                `,
              },
              (data) => (!!data ? {
                ...data,
                cardsCount: data.cardsCount
                  ? data.cardsCount - 1
                  : data.cardsCount,
              } : undefined)
            );
          }
        }
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted() {
        handleNavigateBack();
      },
    });
  }, [handleNavigateBack, deleteTalent, talent]);

  return (
    <Modal
      trigger={(getTriggerProps) => (
        <ActionButton icon="trashbin" text="Delete" {...getTriggerProps()} />
      )}
      render={({ getContentProps, close }) => (
        <Dialog
          title="Delete talent profile"
          content={
            <>
              Are you sure you want to delete <strong>"{talent.name}"</strong>{" "}
              profile? This operation CAN NOT be undone.
            </>
          }
          buttons={
            <>
              <Button $kind="text" onClick={close} disabled={loading}>
                Cancel
              </Button>
              <Button
                $kind="contained"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                Delete talent profile
              </Button>
            </>
          }
          close={close}
          {...getContentProps()}
        />
      )}
    />
  );
}
